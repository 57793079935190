<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2 table-border-all"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.create_time')"
                :placeholder="$t('labels.create_time')"
                name="created_at"
                sort-name="created_at"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 100px">
              <SelectWarehouse
                class="c-input-xs input-filter"
                :label="$t('labels.warehouse_1')"
                :placeholder="$t('labels.warehouse_1')"
                name="id_warehouse"
                sort-name="warehouse_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.combo_name')"
                :placeholder="$t('labels.combo_name')"
                name="combo_name"
                sort-name="combo_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.combo_code')"
                :placeholder="$t('labels.combo_code')"
                name="combo_barcode"
                sort-name="combo_barcode"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilterFromTo
                :label="$t('labels.combo_available')"
                :placeholder="$t('labels.combo_available')"
                name="available"
                sort-name="available"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.price')"
                :placeholder="$t('labels.price')"
                name="price"
                sort-name="price"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.wholesale_price')"
                :placeholder="$t('labels.wholesale_price')"
                name="wholesale_price"
                sort-name="wholesale_price"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="combo_status"
                sort-name="combo_status"
                :options="statusOptions"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <template v-if="checkModule(['admin_physical_combo'])">
              <th>
                <InputFilter
                  :label="$t('labels.physical_combo_code')"
                  :placeholder="$t('labels.physical_combo_code')"
                  name="physical_combo_code"
                  sort-name="physical_combo_code"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center">
                <InputFilterFromTo
                  :label="$t('labels.physical_combo_available')"
                  :placeholder="$t('labels.physical_combo_available')"
                  name="physical_combo_available"
                  sort-name="physical_combo_available"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <th>
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="goods_barcode"
                sort-name="goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.goods_name')"
                :placeholder="$t('labels.goods_name')"
                name="goods_name"
                sort-name="goods_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center red--text">
              <!-- <InputFilter
                :label="$t('labels.goods_description')"
                :placeholder="$t('labels.goods_description')"
                name="goods_description"
                sort-name="goods_description"
                :sorting="filters.sort_by"
                :disabled="true"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              /> -->
              {{ $t("labels.goods_description") }}
            </th>
            <th class="text-center red--text">
              <!-- <InputFilter
                :label="$t('labels.size')"
                :placeholder="$t('labels.size')"
                name="goods_size"
                sort-name="goods_size"
                :disabled="true"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              /> -->
              {{ $t("labels.size") }}
            </th>
            <th class="text-center red--text">
              <!-- <InputFilterFromTo
                :label="$t('labels.combo_quantity')"
                :placeholder="$t('labels.combo_quantity')"
                name="goods_quantity"
                sort-name="goods_quantity"
                :sorting="filters.sort_by"
                :disabled="true"
                :disabled-filter="true"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              /> -->
              {{ $t("labels.combo_quantity") }}
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(item, index) in items"
          :key="`combo_${index}_${item.id}`"
        >
          <tr
            class="text-center"
            v-for="(g, k) in item.goods"
            :key="`cg_${k}_${g.id}`"
          >
            <td v-if="k === 0" :rowspan="item.goods.length">
              {{ formatDateTime(item.created_at) }}
            </td>
            <td v-if="k === 0" :rowspan="item.goods.length">
              {{ item.warehouse_code }}
            </td>
            <td v-if="k === 0" :rowspan="item.goods.length">{{ item.name }}</td>
            <td v-if="k === 0" :rowspan="item.goods.length">
              {{ item.barcode }}
            </td>
            <td v-if="k === 0" :rowspan="item.goods.length">
              {{ formatNumber(item.available) }}
            </td>
            <td v-if="k === 0" :rowspan="item.goods.length">
              <span
                v-if="
                  checkPermission([
                    'customer_account',
                    'administration_goods_price',
                  ])
                "
                class="text-decoration-underline info--text cursor-pointer"
                @click="showUpdatePrice(item)"
              >
                {{ item.price !== null ? formatNumber(item.price) : "_" }}
              </span>
              <span v-else>
                {{ item.price !== null ? formatNumber(item.price) : "_" }}
              </span>
            </td>
            <td v-if="k === 0" :rowspan="item.goods.length">
              <span
                v-if="
                  checkPermission([
                    'customer_account',
                    'administration_goods_price',
                  ])
                "
                class="text-decoration-underline info--text cursor-pointer"
                @click="showUpdatePrice(item)"
              >
                {{
                  item.wholesale_price !== null
                    ? formatNumber(item.wholesale_price)
                    : "_"
                }}
              </span>
              <span v-else>
                {{
                  item.wholesale_price !== null
                    ? formatNumber(item.wholesale_price)
                    : "_"
                }}
              </span>
            </td>
            <td v-if="k === 0" :rowspan="item.goods.length">
              {{ item.active ? "Yes" : "No" }}
              <!-- <v-switch
                class="switch-center"
                v-model="item.active"
                @change="switchComboStatus(item)"
                :key="`swc_${item.id}_${item.active}`"
              ></v-switch> -->
            </td>
            <template v-if="checkModule(['admin_physical_combo'])">
              <td v-if="k === 0" :rowspan="item.goods.length">
                {{ item.physical_combo_code }}
              </td>
              <td v-if="k === 0" :rowspan="item.goods.length">
                {{ item.physical_combo_available }}
              </td>
            </template>
            <td>{{ g.customer_goods_barcode }}</td>
            <td>{{ g.name }}</td>
            <td>{{ g.description }}</td>
            <td>{{ g.size }}</td>
            <td>{{ g.quantity }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex justify-start align-center">
          <v-btn color="info" class="mr-2" @click="downloadExcel">
            <v-icon>mdi-download</v-icon> {{ $t("labels.excel") }}
          </v-btn>
          <!-- <span class="table-footer-count">
            {{ $t("labels.sum_combo", { count: total }) }}
          </span> -->
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="d-flex align-center justify-end">
          <div style="max-width: 90px">
            <v-text-field
              class="c-input-xs mr-2"
              dense
              outlined
              hide-details
              v-model.number="page"
              :label="$t('labels.page')"
              :placeholder="$t('labels.page')"
            ></v-text-field>
          </div>
          <v-btn color="success" @click="showComboCreate">
            {{ $t("labels.add_combo") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogComboCreate" persistent max-width="1024px">
      <ComboCreateMulti
        v-if="dialogComboCreate"
        @cancel="hideComboCreate"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="dialogUpdatePrice" persistent max-width="350px">
      <UpdateComboPrice
        v-if="dialogUpdatePrice"
        :item="updatingGoods"
        @cancel="hideUpdatePrice"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { IMAGE_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "Combo",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    ComboCreateMulti: () => import("@/components/goods/ComboCreateMulti"),
    UpdateComboPrice: () => import("@/components/goods/UpdateComboPrice"),
  },
  data: () => ({
    isLoading: false,
    items: [],
    page: 1,
    totalPage: 1,
    total: 0,
    filters: {},
    statusOptions: [...YES_NO_OPTIONS],
    imageOptions: [...IMAGE_OPTIONS],
    dialogComboCreate: false,
    dialogComboCreateMulti: true,
    dialogUpdatePrice: false,
    updatingGoods: {},
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList: debounce(function () {
      httpClient
        .post("/goods-list-combo", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.items = [...data.rows];
        });
    }, 1000),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "ds-combo.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-list-combo-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    async switchComboStatus(combo) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/goods-change-combo-status", {
          id: combo.id,
          active: combo.active ? 1 : 0,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
      } catch (e) {
        this.isLoading = false;
        this.$vToastify.error(this.$t("messages.system_error_try_again"));
      }
    },

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showComboCreate() {
      this.dialogComboCreate = true;
    },
    hideComboCreate() {
      this.updatingGoods = {};
      this.dialogComboCreate = false;
    },
    showComboCreateMulti() {
      this.dialogComboCreateMulti = true;
    },
    hideComboCreateMulti() {
      this.dialogComboCreateMulti = false;
    },
    showUpdatePrice(goods) {
      this.updatingGoods = { ...goods };
      this.dialogUpdatePrice = true;
    },
    hideUpdatePrice() {
      this.updatingGoods = {};
      this.dialogUpdatePrice = false;
    },
  },
};
</script>

<style scoped></style>
